@import url("https://fonts.googleapis.com/css2?family=Michroma&family=Montserrat:wght@400;600&display=swap");

@media (min-width: 1200px) {
  .navigation {
    max-height: 550px;
    max-width: 308px;
    top: unset;
    width: unset;
  }
}

.navigation {
  background: #fff;
  margin-left: 40px;
  max-height: 0;
  padding: 0 10px;
  margin-top: 90px;
  transition: max-height 0.2s ease;
  width: calc(100% - 27px);
  z-index: 10;
  list-style: none;
}

.tds-list,
.tds-text--body,
.tds-text--caption-header,
.tds-text--h1,
.tds-text--h2,
.tds-text--h3,
.tds-text--h4,
.tds-text--h5,
.tds-text--h6 {
  line-height: 20px;
}

.tds-list > :is(li, dd):not(:last-of-type) {
  -webkit-margin-after: 8px;
  margin-block-end: 8px;
}

.navigation-link {
  margin: 5px 0 8px;
  padding: 5px 0;
}

.tds-list > :is(li, dd) {
  -webkit-padding-end: 0;
  -webkit-padding-start: 0;
  padding-inline-end: 0;
  padding-inline-start: 0;
  position: relative;
}

[dir="ltr"] .navigation-link a {
  padding-left: 46px;
}

.navigation-link.disabled a {
  color: #aaa;
}

.navigation-link a {
  box-shadow: none;
  color: var(--tds-color--grey30);
  display: block;
  font-family: var(--tds-font-combined);
  font-size: 14px;
  font-weight: 500;
  line-height: 34px;
  transition: color 0.2s ease;
}

a {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}

.navigation-link.selected .scrim,
.navigation-title .scrim {
  background: var(--tds-color--grey65);
  border-radius: 50%;
}

[dir="ltr"] .navigation-link .scrim,
[dir="ltr"] .navigation-title .scrim {
  margin-left: -46px;
  margin-right: 12px;
}

.navigation-link .scrim,
.navigation-title .scrim {
  display: inline-flex;
  align-items: center;
  height: 34px;
  line-height: 40px;
  margin: 0;
  text-align: center;
  vertical-align: bottom;
  width: 34px;
}

.navigation-link.selected svg,
.navigation-link:hover svg {
  opacity: 1;
}

.navigation-link svg {
  margin-bottom: 1px;
  opacity: 0.72;
  transition: opacity 0.2s ease;
  height: 20px;
}

.font-alt {
  font-family: "Michroma", sans-serif;
}

.account {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.wrapper {
  flex: 1;
  display: flex;
  overflow: auto;
  background: #f5f5f5;
}

.wrapper main {
  flex: 1;
  margin-top: 25px;
}

.header .logo {
  padding: 15px;
}

.header .buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
}

.account-footer {
  padding: 15px;
  text-align: center;
  background-color: #262626;
  color: #bdbdbd;
  font-size: 8px;
  font-family: "Michroma", sans-serif;
}

.website {
  margin-right: 25px;
}

.website a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #262626;
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 18px;
  padding: 6px;
  font-size: 10px;
  font-family: "Michroma", sans-serif;
}

.website a img {
  margin-right: 8px;
}

.notification {
  margin-right: 25px;
}

.notification a {
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 18px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile {
}

.profile a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #262626;
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 18px;
  padding: 6px;
  padding-right: 40px;
  font-size: 10px;
  font-family: "Michroma", sans-serif;
  background-repeat: no-repeat;
  background-position: right center;
  background-position-x: 115px;
}

.profile a img {
  margin-right: 8px;
  width: 28px;
}

.dashboard-header {
  font-size: 26px;
  margin-bottom: 50px;
  font-family: "Michroma";
}

.dashboard-card {
  position: relative;
  height: 225px;
  margin-bottom: 20px;
  border-radius: 16px;
  overflow: hidden;
}

.dashboard-card .dashboard-link {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #00000066;
  font-size: 18px;
  font-family: "Michroma";
}

.account .login-form {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 20px;
}

.account .login-title-sm {
  font-size: 10px;
}

.account .login-title-md {
  font-size: 16px;
  margin-bottom: 25px;
}

.account .login-line {
  text-align: center;
}

.account .login-line input {
  width: 100%;
  border: 1px solid #262626;
  border-radius: 8px;
  font-size: 14px;
  padding: 10px;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.account .login-line select {
  width: 100%;
  padding: 10px 5px;
  border-radius: 8px;
}

.account .button-dark {
  font-family: "Michroma", sans-serif;
  background: #262626;
  border-radius: 8px;
  font-size: 10px;
  color: #fff !important;
  padding: 12px 50px;
  box-shadow: none;
  -webkit-appearance: button;
  outline: none;
  text-decoration: none !important;
  border: none;
  cursor: pointer;
}

.account .button-link {
  appearance: none;
  background: none;
  border: none;
  color: #6495ed;
  text-decoration: underline;
  cursor: pointer;
}

.account .button-dark.narrow {
  padding: 10px;
}

.account .button-white {
  font-size: 10px;
  color: #262626;
  text-decoration: none;
  border: 1px solid #262626;
  border-radius: 8px;
  padding: 10px 15px;
  box-sizing: border-box;
  margin-right: 15px;
  transition-duration: 0.3s;
}

.account .button-white:hover {
  background: #262626;
  color: #fcd801;
}

.account .login-form a {
  font-family: "Michroma", sans-serif;
  color: #262626;
  text-decoration: none !important;
  font-size: 10px;
}

.bookmarks {
  padding: 15px 0;
  background: #f5f5f5;
}

.bookmarks .link {
  text-decoration: none !important;
  font-size: 10px;
  font-family: "Michroma", sans-serif;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  color: #262626;
  height: 100%;
  transition-duration: 0.3s;
}

.bookmarks .link:hover {
  background: #262626;
  color: #fcd801;
}

.dashboard .row,
.dashboard .col,
.dashboard .card {
  height: 100%;
}

.dashboard .card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.box {
  margin: 12px 0;
  flex: 1;
  background-size: cover;
  border-radius: 15px;
  overflow: hidden;
  text-decoration: none;
  font-size: 16px;

  transition-duration: 0.3s;
}

.box.hover {
  color: #fff;
}

.box.hover:hover {
  color: #fcd801;
}

.box .title {
  font-family: "Michroma", sans-serif;
  margin-left: 15px;
  margin-top: 15px;
}

.box .welcome {
  display: inline;
  font-size: 20px;
  border-bottom: 1px solid #fcd800;
  color: #262626;
  font-weight: bold;
}

.box .yacht-logo {
  margin-top: 25px;
}

.box .conf-id {
  font-size: 12px;
  color: #262626;
  text-decoration: none;
}

.box .buttons {
  margin-top: 20px;
}

.sheet {
  border-radius: 15px;
  padding: 20px;
  background: #fff;
  margin-bottom: 25px;
}

.nav {
  list-style: none;
  padding: 0;
}

.nav > li {
  margin-bottom: 20px;
}

.nav > li > .parent {
  font-size: 12px;
  color: #b1b0b0;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav > li.active .parent {
  color: #262626;
}

.nav > li > .icon {
  margin-right: 10px;
}

.nav .text {
  flex: 1;
  padding-left: 10px;
}

.nav-child ul {
  list-style: none;
  margin-top: 10px;
}

.nav-child ul > li > a {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #262626;
  text-decoration: none;
}

.sidenav {
  height: 100%;
  overflow: auto;
}

.title-sub {
  font-size: 10px;
}

.form-wrapper,
.list-wrapper {
  margin-top: 15px;
}

.account .form .row {
  margin-top: 15px;
}

.account .form-item label {
  display: block;
  font-size: 10px;
}

.account .form-item input[type="text"],
.account .form-item input[type="date"],
.account .form-item input[type="password"],
.account .form-item textarea {
  display: block;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  border: 1px solid #262626;
  padding: 10px 15px;
  outline: none;
  margin-top: 5px;
}

.account .form-item select {
  display: block;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  border: 1px solid #262626;
  padding: 10px 15px;
  outline: none;
  margin-top: 5px;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-head {
  font-size: 10px;
}

.table-body {
  color: #878787;
  font-size: 10px;
}

.table-row {
  box-sizing: border-box;
  padding: 10px 15px;
  border: 1px solid #878787;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 4px;
}

.status-accepted {
  color: #00ef38;
}

.status-rejected {
  color: #ff0000;
}

.sub-active {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  margin-right: 7px;
}

.spec {
  margin-bottom: 25px;
  border-bottom: 1px solid #7f7f7f;
}

.spec-title {
  font-size: 10px;
  color: #7f7f7f;
}

.spec-text {
  font-size: 14px;
}

.warranty-items {
}

.warranty-item {
  display: flex;
  padding: 10px 15px;
  border: 1px solid #878787;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
  text-decoration: none;
}

.warranty-item:hover {
  background-color: #ebebeb;
}

.warranty-item .text {
  font-size: 11px;
  color: #878787;
}

.warranty-item .icon {
}

.row-title {
  cursor: pointer;
}

.table-row .row-content {
  height: 0;
  color: #2c3e50;
  background: white;
  transition: all 1s;
  display: none;
}

.table-row.active .row-content {
  height: auto;
  display: block;
}

.row-content-inner {
  padding: 10px 0;
}

.table-row .row {
  position: relative;
}

.table-row .row::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  position: absolute;
  right: 0;
}

.table-row.no-content .row::after {
  content: "";
}

.table-row.active .row::after {
  transform: rotate(90deg);
}
